<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 pt-3 pb-3">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control"
              v-model="searchData"
              :placeholder="$t('common.search')"
              v-on:keyup.enter="onSearchHistory()"
            />
          </div>
          <div class="col-auto col-md-4">
            <multiselect
              v-model="value"
              :placeholder="$t('historyLog.action')"
              label="text"
              track-by="value"
              :options="actionList"
              :multiple="true"
              :taggable="true"
              :close-on-select="false"
              :custom-label="customLabel"
              @input="addTag"
            >
              <template slot="singleLabel" slot-scope="props">
                {{$t(props.option.text)}} asadada
              </template>
              <template slot="option" slot-scope="props">
                <span class="option__title">{{$t(props.option.text)}}</span>
              </template>
            </multiselect>
          </div>
          <!-- <b-button
            variant="success"
            size="sm"
            class="send-reason-absence float-left pl-4 pr-4"
            @click="onSearchHistory()"
            >{{ $t("common.search") }}</b-button
          > -->
        </div>
        <div class="row mt-2">
          <div class="col">
            <b-button
              variant="success"
              size="sm"
              class="send-reason-absence float-right pl-4 pr-4"
              @click="onSearchHistory()"
              >{{ $t("common.search") }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-md-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th scope="col">{{ $t("common.nonumber") }}</th>
              <th scope="col">{{ $t("historyLog.userAction") }}</th>
              <th scope="col">{{ $t("historyLog.action") }}</th>
              <th scope="col">{{ $t("historyLog.dateCreated") }}</th>
              <th scope="col">
                {{ $t("historyLog.description") }}
              </th>
              <!-- <th scope="col">{{ $t("common.action") }}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in historyList" :key="item + index">
              <td>{{ item.nonumber }}</td>
              <td class="text-left">{{ item.userCreated }}</td>
              <td class="text-left">{{ $t(getActionText(item.actionType)) }}</td>
              <td class="text-right">{{item.dateCreated}}</td>
              <td class="text-left">{{ item.description }}</td>
              <!-- <td>
                <b-button
                  type="button"
                  class="btn btn-danger"
                  @click="onShowModalConfirmDeleteNotification(item)"
                  size="sm"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </b-button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <pagination
          v-if="historyList.length > 0"
          :rows="historyPaginate.total"
          :per-page="historyPaginate.perPage"
          :current-page="historyPaginate.currentPage"
          @onPageChange="onHistoryPageChange"
        ></pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import stringUtils from "utils/stringUtils";
import moment from 'moment'
export default {
  components: {
    Multiselect,
  },
  data: () => ({
    searchData: null,
    searchActionList: [],
    historyList: [],
    historyPaginate: {
      total: 1,
      perPage: 1,
      currentPage: 1
    },
    //
    value: [],
    options: [
      { name: "Vue.js", code: "vu" },
      { name: "Javascript", code: "js" },
      { name: "Open Source", code: "os" },
    ],
    actionList: stringUtils.HistoryLogAction
  }),
  created() {
    this.getHistoryData();
  },
  methods: {
    onSearchHistory: function() {
      this.getHistoryData();
    },
    onHistoryPageChange: function(page) {
      this.historyPaginate.currentPage = page
      this.getHistoryData()
    },
    getHistoryData: function() {
      let filter = {
        params: {
          searchText: this.searchData,
          page: this.historyPaginate.currentPage,
          searchActionList: this.searchActionList,
          event_id: sessionStorage.getItem("event_id")
        },
      };
      this.GET_HISTORY_LOG(filter).then(
        function (res) {
          let results = res.data.results != undefined ? res.data.results : []
          let paginate = {
            totalPage: res.data.num_pages,
            currentPage: res.data.page,
            pageSize: res.data.page_size,
            perPage: res.data.per_page,
            total: res.data.count
          }
          this.historyPaginate = {
            total: paginate.totalPage,
            perPage: 1,
            currentPage: paginate.currentPage
          }
          this.historyList = []
          results.forEach((data, index)=> {
            let item = {
              nonumber: (index + 1) + (paginate.currentPage - 1) * paginate.pageSize,
              userCreated: data.user_created.last_name + ' ' + data.user_created.first_name,
              dateCreated: moment(data.date_created).format('HH:mm DD-MM-YYYY'),
              actionType: data.action_type,
              description: data.description
            }
            this.historyList.push(item)
          })
        }.bind(this)
      )
    },
    getActionText: function (value) {
      let index = stringUtils.HistoryLogAction.findIndex(x=> x.value === value)
      return index !== -1 ? stringUtils.HistoryLogAction[index].text : ''
    },
    addTag: function (actionList) {
      this.searchActionList = []
      if (actionList.length > 0) {
        actionList.map(x => {
          this.searchActionList.push(x.value)
        })
      }
    },
    customLabel: function (lable) {
      return this.$t(lable.text)
    },
    ...mapActions(["GET_HISTORY_LOG"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
tr td:last-child {
  width: 8%;
  white-space: nowrap;
}
tr td:first-child {
  width: 1%;
  white-space: nowrap;
}
.send-reason-absence {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
// /deep/ .multiselect__input {
//   background: red;
//   width: 100% !important;
// }
</style>
